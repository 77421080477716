<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :toggle="toggle"
    :relations="relations"
    :query-values="queryValues"
    :query-selects="querySelects"
    entity-type="place"
    locale-section="pages.places"
    field-section="places"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "PlaceDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: place
        place
        toraId
        parishes {
          parish {
            id
          }
          current
        }
      `,
      querySelects: /* GraphQL */ `
        parish: parishes {
          id
          hundred {
            province {
              province
            }
            hundred
          }
          parish
        }
      `,
      inputAttributes: {
        place: { required: true },
        toraId: { type: "number" }
      },
      toggle: {
        current: {
          current: true,
          notCurrent: false
        }
      },
      relations: {
        parishes: {
          parish: {
            id: null
          },
          current: false
        }
      }
    };
  }
};
</script>
